import { adminFlagData } from "../utils/common";

const API_URL = process.env.REACT_APP_backendServiceURL; 


const Apis = {
    BusinessForm: `${API_URL}/api/business/business-requests`,
    CheckEmailPhoneNoBusiness: (data) => `${API_URL}/api/business/check-email-phone?email=${data.email}&contactNumber=${data.contactNumber}&countryCode=${data.countryCode}`,
    CountryData: `${API_URL}/api/location/country`,
    StateData: `${API_URL}/api/location/state`,
    CityData: `${API_URL}/api/location/city`,
    UploadAttachmentData: `${API_URL}/api/file/upload`,
    TeamListData: `${API_URL}/api/wse/team/list/getEnableTeamMember`,
    TeamListDataByPosition: (positionId) => `${API_URL}/api/wse/team/getList/${positionId}`,
    GetAllPositionData: `${API_URL}/api/wse/enabledPositions`,
    GetPersonaData: `${API_URL}/api/persona/personas/list/enablePersona`,
    GetInterestData: `${API_URL}/api/persona/interests/list/enableInterest`,
    GetTriviaData:  (data) => `${API_URL}/api/trivia/enable/list?page=${data?.page}&size=${data?.size}`,
    GetTriviaDataBySlug: (slug) => `${API_URL}/api/trivia/getData?slug=${slug}`,
    GetTriviaDataByCategory: (data) => `${API_URL}/api/trivia/getByCategory/${data?.categoryId}?page=${data?.page}&size=${data?.size}`,
    GetRecentTrivias: (count, triviaId) => `${API_URL}/api/trivia/recent/${count}/${triviaId}`,
    GetRecentHeaderTrivias: (count) => `${API_URL}/api/trivia/recent/${count}`,
    GetRelatedTrivias: (categoryId, count) => `${API_URL}/api/trivia/relatedByCategory/${categoryId}/${count}`,
    GetInfrograpicsData: (data) => `${API_URL}/api/infrograpic/enable/list?page=${data?.page}&size=${data?.size}`,
    GetInfrograpicsDataBySlug: (slug) => `${API_URL}/api/infrograpic/getData?slug=${slug}`,
    GetInfrograpicDataByCategory: (data) => `${API_URL}/api/infrograpic/getByCategory/${data?.categoryId}?page=${data?.page}&size=${data?.size}`,
    GetRecentInfrograpics: (count, infoId) => `${API_URL}/api/infrograpic/recent/${count}/${infoId}`,
    GetRecentHeaderInfrograpics: (count) => `${API_URL}/api/infrograpic/recent/${count}`,
    GetRelatedInfrograpics: (categoryId, count) => `${API_URL}/api/infrograpic/relatedByCategory/${categoryId}/${count}`,
    GetCategories: `${API_URL}/api/categories/get-categories`,
    GetBlogDetailsBySlug: (slug) => `${API_URL}/api/blog/blogdetails/${slug}?admin=${adminFlagData()}`,
    GetBlogMetaBySlug: (slug) => `${API_URL}/api/blog/metaKeys/${slug}`,
    GetAllBlogs: (data) => `${API_URL}/api/blog/enable/list?page=${data?.page}&size=${data?.size}`,
    GetRecentBlogs: (count, blogId) => `${API_URL}/api/blog/recent/${count}/${blogId}`,
    GetReactHeaderBlogs: (count) => `${API_URL}/api/blog/recent/${count}`,
    GetBlogDataByCategory: (data) => `${API_URL}/api/blog/categories/get/${data?.categoryId}?page=${data?.page}&size=${data?.size}`,
    GetRecentData: (count) => `${API_URL}/api/trivia/get-recent-data?limit=${count}`,
    CreateUserViewQuery: `${API_URL}/api/user-view-query/create`
}

export {API_URL, Apis}