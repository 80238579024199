import { DeviceUUID } from 'device-uuid';
import { v4 as uuidv4 } from 'uuid';
import dateUtil from "date-and-time";

export const getDeviceId = () => {
    var du = new DeviceUUID().parse();
    var dua = [
        du.language,
        du.platform,
        du.os,
        du.cpuCores,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isKindleFire,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWindows,
        du.isLinux,
        du.isLinux64,
        du.isMac,
        du.isiPad,
        du.isiPhone,
        du.isiPod,
        du.isSmartTV,
        du.pixelDepth,
        du.isTouchScreen,
    ];
    return dua.join("-");
}

export const deviceId = () => {
    const Deviceuuid = new DeviceUUID().get() + '-' + uuidv4();
    if (!localStorage.getItem("deviceuuID")) {
        localStorage.setItem("deviceuuID", Deviceuuid);
    }
}

export const getUserDataFromLocalStorage = () => {
    let userData = localStorage.getItem('userDatails');
    if (userData === undefined || userData === null) {
        return null;
    }
    return JSON.parse(userData);
}

export const getCategorySlug = (category) => {
    return category?.replace(/ /g,"-")?.toLowerCase();
}

export const getCategoriesFromLocalStorage = () => {
    let categories = localStorage.getItem('userCategories');
    if (categories === undefined || categories === null) {
        return null;
    }
    return JSON.parse(categories);
}

export const getFormattedDate = (date) => {
    return dateUtil.format(new Date(date), "DD MMM YYYY")
}

export const userResourceOptions = [
    {label: "Blog", value: "BLOG"},
    {label: "Trivia", value: "TRIVIA"},
    {label: "Infographic", value: "INFOGRAPHIC"}
]

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const removeSpecialCharacter = (data) => {
    return data && data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
}

export const numberFormatter = (number) => {
    return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const redirectToCareerPage = () => {
    let url = process.env.REACT_APP_infratechCareerURL;
    if(process.env.REACT_APP_ENV === "prod") {
        url = "https://career.wseinfratech.com/";
    }
    window.location.href = url
}

export const redirectToProductPage = () => {
    let url = process.env.REACT_APP_infratechProductURL;
    if(process.env.REACT_APP_ENV === "prod") {
        // url = "https://career.wseinfratech.com/";
    }
    window.location.href = url
}

export const adminFlagData = () => {
    const urlParams = new URLSearchParams(window.location.search && window.location.search);
    const adminFlag = urlParams.get('admin');
    return adminFlag || "";
}

export const getUrlParamsValue = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const value = urlParams.get('pathName');
    return value || "";
}