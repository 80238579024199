import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'
import { retry } from '../utils/PromiseRetry';

const Home = React.lazy(() => retry(() => import('./components/web/views/Home')));
const AboutUs = React.lazy(() => retry(() => import('./components/web/views/AboutUs')));
const WhatWeDo = React.lazy(() => retry(() => import('./components/web/views/WhatWeDo')));
const WhatWeOfferPage = React.lazy(() => retry(() => import('./components/web/views/WhatWeDo/WhatWeOffer/WhatWeOfferPage')));
const OurPlans = React.lazy(() => retry(() => import('./components/web/views/OurPlans')));
const WorkingTogetherPage = React.lazy(() => retry(() => import('./components/web/views/OurPlans/WorkingTogether/WorkingTogetherPage')));
const WhyUs = React.lazy(() => retry(() => import('./components/web/views/WhyUs')));
const OurFamily = React.lazy(() => retry(() => import('./components/web/views/OurFamily')));
const ContactUs = React.lazy(() => retry(() => import('./components/web/views/ContactUs')));
const Trivia = React.lazy(() => retry(() => import('./components/web/views/Trivia')));
const Infographics = React.lazy(() => retry(() => import('./components/web/views/Infographics')));
const Blog = React.lazy(() => retry(() => import('./components/web/views/Blog')));
const TriviaDetails = React.lazy(() => retry(() => import('./components/web/views/TriviaDetails')));
const InfographicsDetails = React.lazy(() => retry(() => import('./components/web/views/InfographicsDetails')));
const BlogDetails = React.lazy(() => retry(() => import('./components/web/views/BlogDetails')));
const TriviaListByCategory = React.lazy(() => retry(() => import('./components/web/views/Trivia/TriviaListByCategory')));
const InfrograpicListByCategory = React.lazy(() => retry(() => import('./components/web/views/Infographics/InfrograpicListByCategory')));
const BlogListByCategory = React.lazy(() => retry(() => import('./components/web/views/Blog/BlogListByCategory')));
const PrivacyPolicy = React.lazy(() => retry(() => import('./components/web/views/PrivacyPolicy')));
const TermsOfService = React.lazy(() => retry(() => import('./components/web/views/TermsOfService')));
const ThankYouPage = React.lazy(() => retry(() => import('./components/web/views/ThankYouPage')));
const UserQueryForm = React.lazy(() => retry(() => import('./components/web/views/UserQueryForm')));
const NotFound = React.lazy(() => retry(() => import('./components/commons/404')));
const Microservices = React.lazy(() => retry(() => import('./components/web/views/OurProducts/Microservices')));
const MicroServiceDetailsPage = React.lazy(() => retry(() => import('./components/web/views/OurProducts/Microservices/MicroServiceDetailsPage')));
const AppsDetailsPage = React.lazy(() => retry(() => import('./components/web/views/OurProducts/App/AppsDetailsPage')));



const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    children: [
      { path: "", element: <Home /> },
      { path: "search", element: <Home /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "what-we-do", element: <WhatWeDo /> },
      { path: "what-we-do/:slug", element: <WhatWeOfferPage /> },
      { path: "our-plans", element: <OurPlans /> },
      { path: "our-plans/:slug", element: <WorkingTogetherPage /> },
      { path: "why-us", element: <WhyUs /> },
      { path: "wse-legacy", element: <OurFamily /> },
      { path: "blog", element: <Navigate to="/blogs" replace /> },
      { path: "blogs", element: <Blog /> },
      { path: "trivia", element: <Trivia /> },
      { path: "infographics", element: <Infographics /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "book-a-free-consultation", element: <UserQueryForm /> },
      // { path: "our-products", element: <OurProducts /> },
      { path: "microservices", element: <Microservices /> },
      { path: "microservices/:slug", element: <MicroServiceDetailsPage /> },
      { path: "apps/:slug", element: <AppsDetailsPage /> },
      { path: "blog/:slug", element: <BlogDetails /> },
      { path: "trivia/:slug", element: <TriviaDetails /> },
      { path: "infographics/:slug", element: <InfographicsDetails /> },
      { path: "blog-category/:category", element: <BlogListByCategory /> },
      { path: "trivia-category/:category", element: <TriviaListByCategory /> },
      { path: "infographics-category/:category", element: <InfrograpicListByCategory /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "terms-of-service", element: <TermsOfService /> },
      { path: "thank-you", element: <ThankYouPage /> },
      { path: "book-a-free-consultation-thank-you", element: <ThankYouPage /> }
    ],
  },
]);

function App() {
  return (
    <Suspense fallback={
      <span style={{ textAlign: "center", display: "block" }}>
        <div
          style={{
            display: "inline-block",
            marginTop: "300px",
          }}
        >
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={false}
            timeout={8000}
          />
        </div>
      </span>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
